.t {
  transform: none;
}
[news-article] {
  padding-bottom: 8.75rem;
}
[news-article] a {
  text-decoration: underline;
  text-underline-position: under;
}
[news-article] em {
  font-style: italic;
}
[news-article] .hero {
  padding-top: 8.25rem;
  padding-right: 0rem;
  padding-bottom: 3rem;
  padding-left: 0rem;
  position: relative;
  z-index: 2;
}
[news-article] .hero .text-area {
  margin-top: 0rem;
  margin-right: 1.125rem;
  margin-bottom: 0rem;
  margin-left: 1.125rem;
}
[news-article] .hero .text-area h2,
[news-article] .hero .text-area .tag,
[news-article] .hero .text-area .share-btn-wrap {
  width: 100%;
}
[news-article] .hero h2 {
  font-size: 1.75rem;
  line-height: 0.09375rem;
  letter-spacing: -0.015625rem;
  line-height: 1.5;
  color: #1e1e1e;
  padding-bottom: 2.25rem;
}
[news-article] .hero .tag {
  font-size: 0.75rem;
  line-height: 0.09375rem;
  letter-spacing: -0.015625rem;
  line-height: 1.5;
  color: #333;
  padding-bottom: 1.5rem;
}
[news-article] .hero .tag span {
  display: inline-block;
  padding-left: 0.75rem;
  font-weight: 500;
}
[news-article] .hero .desc p {
  position: relative;
  padding-left: 0.9375rem;
  font-size: 1rem;
  line-height: 0.12125rem;
  letter-spacing: -0.015625rem;
  line-height: 1.94;
  color: #1e1e1e;
  font-weight: 500;
}
[news-article] .hero .desc p:before {
  content: '';
  display: block;
  position: absolute;
  left: 0rem;
  top: 0.9375rem;
  right: auto;
  bottom: auto;
  width: 0.25rem;
  height: 0.25rem;
  transform: translateY(-50%);
  background-color: #1e1e1e;
  border-radius: 50%;
}
[news-article] .hero [sns-share] {
  width: 2.5rem;
  height: 2.5rem;
  background-color: transparent;
  margin-bottom: 3rem;
  background-image: url('/img/common/btn-share-black.png');
  background-size: contain;
  background-repeat: no-repeat;
}
[news-article] .hero [cdn-img] {
  width: 100%;
  display: block;
}
[news-article].common .hero {
  padding-bottom: 2.25rem;
}
[news-article].common .hero .kv-img {
  max-width: 68.75rem;
  margin: 0 auto;
  display: none;
}
[news-article].common .hero [cdn-img] {
  width: 100%;
}
[news-article].common .hero .share-btn-wrap [sns-share] {
  margin: 0rem;
}
[news-article].magazine .hero {
  text-align: center;
  max-width: 120rem;
  margin-left: auto;
  margin-right: auto;
}
[news-article].magazine .hero .kv-img {
  width: 100%;
  height: 31.25rem;
  position: relative;
  overflow: hidden;
}
[news-article].magazine .hero .kv-img [cdn-img] {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}
[news-article].magazine .hero .share-btn-wrap [sns-share] {
  margin-left: auto;
  margin-right: auto;
}
[news-article].webtoon .hero {
  text-align: center;
  padding-bottom: 2.25rem;
}
[news-article].webtoon .hero .share-btn-wrap [sns-share] {
  margin: 0rem;
}
[news-article].full .hero,
[news-article].full-video .hero {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  margin-bottom: 3rem;
  position: relative;
}
[news-article].full .hero .text-area,
[news-article].full-video .hero .text-area {
  position: absolute;
  z-index: 2;
  width: 100%;
  margin: 0rem;
  padding-top: 0rem;
  padding-right: 1.125rem;
  padding-bottom: 0rem;
  padding-left: 1.125rem;
  box-sizing: border-box;
  left: 50%;
  bottom: 0rem;
  right: auto;
  top: auto;
  transform: translateX(-50%);
}
[news-article].full .hero .tag,
[news-article].full-video .hero .tag,
[news-article].full .hero h2,
[news-article].full-video .hero h2,
[news-article].full .hero .share-btn-wrap,
[news-article].full-video .hero .share-btn-wrap {
  position: relative;
  color: #fff;
  text-align: center;
}
[news-article].full .hero button,
[news-article].full-video .hero button {
  background-image: url('/img/common/btn-share-white.png');
}
[news-article].full .hero .kv-img,
[news-article].full-video .hero .kv-img {
  z-index: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  left: 0rem;
  top: 0rem;
  right: auto;
  bottom: auto;
}
[news-article].full .hero .kv-img [cdn-img],
[news-article].full-video .hero .kv-img [cdn-img],
[news-article].full .hero .kv-img [cdn-video],
[news-article].full-video .hero .kv-img [cdn-video] {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
[news-article].full .hero .share-btn-wrap [sns-share],
[news-article].full-video .hero .share-btn-wrap [sns-share] {
  margin-left: auto;
  margin-right: auto;
}
[news-article].webtoon .hero .share-btn-wrap [sns-share] {
  margin-left: auto;
  margin-right: auto;
}
[news-article].webtoon .close-image {
  padding: 0;
}
[news-article].webtoon .close-image img {
  display: block;
}
[news-article] .h0 h3 {
  font-size: 3rem;
  line-height: 0.0675rem;
  letter-spacing: -0.015625rem;
  line-height: 1.08;
  font-weight: 800;
  padding-top: 1rem;
  padding-right: 0rem;
  padding-bottom: 1rem;
  padding-left: 0rem;
  color: #1e1e1e;
  margin-top: 0rem;
  margin-right: 1.125rem;
  margin-bottom: 0rem;
  margin-left: 1.125rem;
}
[news-article] h3 {
  font-size: 1.625rem;
  line-height: 0.09375rem;
  letter-spacing: -0.015625rem;
  line-height: 1.5;
  font-weight: 700;
  padding-top: 1rem;
  padding-right: 0rem;
  padding-bottom: 1rem;
  padding-left: 0rem;
  color: #1e1e1e;
  margin-top: 0rem;
  margin-right: 1.125rem;
  margin-bottom: 0rem;
  margin-left: 1.125rem;
}
[news-article] h4 {
  font-size: 1.25rem;
  line-height: 0.1rem;
  letter-spacing: -0.015625rem;
  line-height: 1.6;
  font-weight: 700;
  padding-top: 1rem;
  padding-right: 0rem;
  padding-bottom: 1rem;
  padding-left: 0rem;
  color: #1e1e1e;
  margin-top: 0rem;
  margin-right: 1.125rem;
  margin-bottom: 0rem;
  margin-left: 1.125rem;
}
[news-article] h5 {
  font-size: 1.125rem;
  line-height: 0.09375rem;
  letter-spacing: -0.015625rem;
  line-height: 1.5;
  font-weight: 700;
  padding-top: 1.25rem;
  padding-right: 0rem;
  padding-bottom: 0.625rem;
  padding-left: 0rem;
  color: #1e1e1e;
  margin-top: 0rem;
  margin-right: 1.125rem;
  margin-bottom: 0rem;
  margin-left: 1.125rem;
}
[news-article] .prepend-desc {
  padding-top: 1rem;
}
[news-article] .prepend-desc p {
  font-size: 0.875rem;
  line-height: 0.106875rem;
  line-height: 1.71;
  color: #004c98;
  font-weight: 700;
  padding-bottom: 0.5rem;
  margin-top: 0rem;
  margin-right: 1.125rem;
  margin-bottom: 0rem;
  margin-left: 1.125rem;
}
[news-article] .prepend-desc h3 {
  padding-top: 0rem;
}
[news-article] .body-text {
  padding-top: 1rem;
  padding-right: 0rem;
  padding-bottom: 1rem;
  padding-left: 0rem;
  margin-top: 0rem;
  margin-right: 1.125rem;
  margin-bottom: 0rem;
  margin-left: 1.125rem;
}
[news-article] .body-text p {
  font-size: 1rem;
  line-height: 0.12125rem;
  letter-spacing: -0.015625rem;
  line-height: 1.94;
  color: #555;
  min-height: 1rem;
}
[news-article] .subtitle-text {
  margin-top: 0rem;
  margin-right: 1.125rem;
  margin-bottom: 0rem;
  margin-left: 1.125rem;
}
[news-article] .subtitle-text i {
  display: none;
}
[news-article] .subtitle-text h5 {
  padding-bottom: 0rem;
  margin: 0rem;
}
[news-article] .subtitle-text .body-text {
  margin: 0rem;
}
[news-article] .list-text,
[news-article] .list-text-1,
[news-article] .list-text-2,
[news-article] .list-text-3,
[news-article] .list-text-4 {
  margin-top: 0rem;
  margin-right: 1.125rem;
  margin-bottom: 0rem;
  margin-left: 1.125rem;
}
[news-article] .list-text .title,
[news-article] .list-text-1 .title,
[news-article] .list-text-2 .title,
[news-article] .list-text-3 .title,
[news-article] .list-text-4 .title {
  position: relative;
  margin-top: 1rem;
}
[news-article] .list-text .title i,
[news-article] .list-text-1 .title i,
[news-article] .list-text-2 .title i,
[news-article] .list-text-3 .title i,
[news-article] .list-text-4 .title i {
  position: absolute;
  width: 1.25rem;
  height: 1.25rem;
  left: 0rem;
  top: 0.875rem;
  right: auto;
  bottom: auto;
  transform: translateY(-50%);
  display: inline-block;
  background-image: url('/img/common/list-head.png');
  background-size: contain;
}
[news-article] .list-text .title h5,
[news-article] .list-text-1 .title h5,
[news-article] .list-text-2 .title h5,
[news-article] .list-text-3 .title h5,
[news-article] .list-text-4 .title h5 {
  padding: 0rem;
  padding-left: 1.25rem;
  margin: 0rem;
  font-size: 1rem;
  line-height: 0.12125rem;
  letter-spacing: -0.015625rem;
  line-height: 1.94;
}
[news-article] .list-text .body-text,
[news-article] .list-text-1 .body-text,
[news-article] .list-text-2 .body-text,
[news-article] .list-text-3 .body-text,
[news-article] .list-text-4 .body-text {
  margin: 0rem;
}
[news-article] .list-text.list-text-1 .title i,
[news-article] .list-text-1.list-text-1 .title i,
[news-article] .list-text-2.list-text-1 .title i,
[news-article] .list-text-3.list-text-1 .title i,
[news-article] .list-text-4.list-text-1 .title i {
  background-image: url('/img/common/list-head-1.png');
}
[news-article] .list-text.list-text-2 .title i,
[news-article] .list-text-1.list-text-2 .title i,
[news-article] .list-text-2.list-text-2 .title i,
[news-article] .list-text-3.list-text-2 .title i,
[news-article] .list-text-4.list-text-2 .title i {
  background-image: url('/img/common/list-head-2.png');
}
[news-article] .list-text.list-text-3 .title i,
[news-article] .list-text-1.list-text-3 .title i,
[news-article] .list-text-2.list-text-3 .title i,
[news-article] .list-text-3.list-text-3 .title i,
[news-article] .list-text-4.list-text-3 .title i {
  background-image: url('/img/common/list-head-3.png');
}
[news-article] .list-text.list-text-4 .title i,
[news-article] .list-text-1.list-text-4 .title i,
[news-article] .list-text-2.list-text-4 .title i,
[news-article] .list-text-3.list-text-4 .title i,
[news-article] .list-text-4.list-text-4 .title i {
  display: none;
}
[news-article] .list-text.list-text-4 .title h5,
[news-article] .list-text-1.list-text-4 .title h5,
[news-article] .list-text-2.list-text-4 .title h5,
[news-article] .list-text-3.list-text-4 .title h5,
[news-article] .list-text-4.list-text-4 .title h5 {
  padding-left: 0rem;
}
[news-article] .small-text {
  font-size: 0.875rem;
  line-height: 0.106875rem;
  letter-spacing: -0.015625rem;
  line-height: 1.71;
  color: #555;
  margin-top: 0rem;
  margin-right: 1.125rem;
  margin-bottom: 0rem;
  margin-left: 1.125rem;
  padding-top: 1rem;
  padding-right: 0rem;
  padding-bottom: 1rem;
  padding-left: 0rem;
}
[news-article] .small-text p {
  min-height: 0.875rem;
}
[news-article] .short-quote,
[news-article] .long-quote,
[news-article] .en-quote {
  padding-top: 3.75rem;
  padding-right: 0rem;
  padding-bottom: 3.75rem;
  padding-left: 0rem;
  text-align: center;
  margin-top: 0rem;
  margin-right: 1.125rem;
  margin-bottom: 0rem;
  margin-left: 1.125rem;
}
[news-article] .quote-comma {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 1.5rem;
  height: 1.5rem;
  background-size: contain;
}
[news-article] .quote-comma:first-of-type {
  margin-bottom: 2.25rem;
  background-image: url('/img/common/quote-comma.png');
  background-size: contain;
}
[news-article] .quote-comma:last-of-type {
  margin-top: 2.25rem;
  background-image: url('/img/common/quote-comma-close.png');
}
[news-article] .short-quote p.main {
  font-size: 1.25rem;
  line-height: 0.10625rem;
  letter-spacing: -0.015625rem;
  line-height: 1.7;
  color: #1e1e1e;
  font-weight: 700;
}
[news-article] .short-quote .sub {
  font-size: 1rem;
  line-height: 0.09375rem;
  letter-spacing: -0.015625rem;
  line-height: 1.5;
  font-weight: 500;
  color: #888;
  padding-top: 1.5rem;
}
[news-article] .long-quote p.main {
  font-size: 1.125rem;
  line-height: 0.11125rem;
  letter-spacing: -0.015625rem;
  line-height: 1.78;
  color: #555;
  font-weight: 500;
}
[news-article] .long-quote .sub {
  font-size: 0.9375rem;
  line-height: 0.104375rem;
  letter-spacing: -0.015625rem;
  line-height: 1.67;
  color: #555;
  padding-top: 1.5rem;
}
[news-article] .en-quote p.main {
  font-size: 1.375rem;
  line-height: 0.096875rem;
  letter-spacing: -0.015625rem;
  line-height: 1.55;
  color: #555;
  font-weight: 500;
}
[news-article] .en-quote .sub {
  font-size: 0.9375rem;
  line-height: 0.104375rem;
  letter-spacing: -0.015625rem;
  line-height: 1.67;
  color: #555;
  padding-top: 0.75rem;
}
[news-article] .profile {
  margin-top: 0rem;
  margin-right: 1.125rem;
  margin-bottom: 0rem;
  margin-left: 1.125rem;
}
[news-article] .profile .name {
  font-size: 1.25rem;
  line-height: 0.1rem;
  letter-spacing: -0.015625rem;
  line-height: 1.6;
  color: #333;
  font-weight: 700;
  padding-top: 1rem;
  padding-right: 0rem;
  padding-bottom: 0.5rem;
  padding-left: 0rem;
}
[news-article] .profile .sum {
  font-size: 1rem;
  line-height: 0.12125rem;
  letter-spacing: -0.015625rem;
  line-height: 1.94;
  color: #333;
  padding-bottom: 1.5rem;
  font-weight: 500;
}
[news-article] .profile .body-text {
  margin: 0rem;
  padding-top: 0rem;
  padding-right: 0rem;
  padding-bottom: 1.5rem;
  padding-left: 0rem;
}
[news-article] .profile hr {
  display: block;
  border-top: 1px solid #ddd;
  margin-bottom: 1rem;
}
[news-article] .footnote {
  margin-top: 0rem;
  margin-right: 1.125rem;
  margin-bottom: 0rem;
  margin-left: 1.125rem;
}
[news-article] .footnote li {
  font-size: 0.75rem;
  line-height: 0.104375rem;
  letter-spacing: -0.015625rem;
  line-height: 1.67;
  color: #014890;
  padding-top: 0.75rem;
}
[news-article] .footnote li span {
  display: inline-block;
  vertical-align: top;
}
[news-article] .footnote li span:first-of-type {
  width: 1.5rem;
}
[news-article] .footnote li span:last-of-type {
  width: calc(100% - 28px);
}
[news-article] .dot-list {
  margin-top: 0rem;
  margin-right: 1.125rem;
  margin-bottom: 0rem;
  margin-left: 1.125rem;
  padding-top: 1rem;
  padding-right: 0rem;
  padding-bottom: 1rem;
  padding-left: 0rem;
}
[news-article] .dot-list p {
  position: relative;
  padding-left: 1.125rem;
  font-size: 1rem;
  line-height: 0.12125rem;
  letter-spacing: -0.015625rem;
  line-height: 1.94;
  color: #555;
}
[news-article] .dot-list p:before {
  content: '';
  display: block;
  position: absolute;
  left: 0rem;
  top: 1rem;
  right: auto;
  bottom: auto;
  width: 0.25rem;
  height: 0.25rem;
  transform: translateY(-50%);
  background-color: #555;
  border-radius: 50%;
}
[news-article] .indent-dot-list {
  margin-top: 0rem;
  margin-right: 1.125rem;
  margin-bottom: 0rem;
  margin-left: 1.125rem;
  padding-top: 0rem;
  padding-right: 0rem;
  padding-bottom: 1rem;
  padding-left: 1.25rem;
}
[news-article] .indent-dot-list p {
  position: relative;
  padding-left: 1.375rem;
  font-size: 1rem;
  line-height: 0.12125rem;
  letter-spacing: -0.015625rem;
  line-height: 1.94;
  color: #555;
}
[news-article] .indent-dot-list p:before {
  content: '';
  display: block;
  position: absolute;
  left: 0rem;
  top: 1rem;
  right: auto;
  bottom: auto;
  width: 0.25rem;
  height: 0.25rem;
  transform: translateY(-50%);
  background-color: #555;
  border-radius: 50%;
}
[news-article] .number-list {
  margin-top: 0rem;
  margin-right: 1.125rem;
  margin-bottom: 0rem;
  margin-left: 1.125rem;
  padding-top: 1rem;
  padding-right: 0rem;
  padding-bottom: 1rem;
  padding-left: 0rem;
  counter-reset: number;
}
[news-article] .number-list p {
  padding-left: 1.625rem;
  position: relative;
  counter-increment: number 1;
  font-size: 1rem;
  line-height: 0.12125rem;
  letter-spacing: -0.015625rem;
  line-height: 1.94;
  color: #555;
}
[news-article] .number-list p:before {
  position: absolute;
  left: 0rem;
  top: 0rem;
  right: auto;
  bottom: auto;
  content: counter(number) '.';
}
[news-article] .basic-btn,
[news-article] .desc-btn,
[news-article] .title-basic-btn,
[news-article] .title-desc-btn {
  margin-top: 0rem;
  margin-right: 1.125rem;
  margin-bottom: 0rem;
  margin-left: 1.125rem;
  border-bottom: 1px solid #ddd;
}
[news-article] .basic-btn .title,
[news-article] .desc-btn .title,
[news-article] .title-basic-btn .title,
[news-article] .title-desc-btn .title {
  font-size: 1.25rem;
  line-height: 0.090625rem;
  letter-spacing: -0.015625rem;
  line-height: 1.45;
  color: #1e1e1e;
  font-weight: 700;
  padding-top: 3rem;
  padding-right: 0rem;
  padding-bottom: 1.25rem;
  padding-left: 0rem;
}
[news-article] .basic-btn a,
[news-article] .desc-btn a,
[news-article] .title-basic-btn a,
[news-article] .title-desc-btn a,
[news-article] .basic-btn .none-btn,
[news-article] .desc-btn .none-btn,
[news-article] .title-basic-btn .none-btn,
[news-article] .title-desc-btn .none-btn {
  font-size: 0.9375rem;
  line-height: 0.0875rem;
  letter-spacing: -0.015625rem;
  line-height: 1.4;
  color: #333;
  font-weight: 700;
  margin-top: 1.5rem;
  margin-right: 0rem;
  margin-bottom: 1.5rem;
  margin-left: 0rem;
  padding-right: 3.5rem;
  width: 100%;
  display: block;
  position: relative;
  text-decoration: none;
  box-sizing: border-box;
}
[news-article] .basic-btn a i,
[news-article] .desc-btn a i,
[news-article] .title-basic-btn a i,
[news-article] .title-desc-btn a i,
[news-article] .basic-btn .none-btn i,
[news-article] .desc-btn .none-btn i,
[news-article] .title-basic-btn .none-btn i,
[news-article] .title-desc-btn .none-btn i {
  background-size: contain;
  width: 1.25rem;
  height: 1.25rem;
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  right: 0rem;
  top: 0rem;
  left: auto;
  bottom: auto;
}
[news-article] .basic-btn a i.landing,
[news-article] .desc-btn a i.landing,
[news-article] .title-basic-btn a i.landing,
[news-article] .title-desc-btn a i.landing,
[news-article] .basic-btn .none-btn i.landing,
[news-article] .desc-btn .none-btn i.landing,
[news-article] .title-basic-btn .none-btn i.landing,
[news-article] .title-desc-btn .none-btn i.landing {
  background-image: url('/img/common/btn-icon-landing.png');
}
[news-article] .basic-btn a i.outlink,
[news-article] .desc-btn a i.outlink,
[news-article] .title-basic-btn a i.outlink,
[news-article] .title-desc-btn a i.outlink,
[news-article] .basic-btn .none-btn i.outlink,
[news-article] .desc-btn .none-btn i.outlink,
[news-article] .title-basic-btn .none-btn i.outlink,
[news-article] .title-desc-btn .none-btn i.outlink {
  background-image: url('/img/common/btn-icon-outlink.png');
}
[news-article] .basic-btn a i.download,
[news-article] .desc-btn a i.download,
[news-article] .title-basic-btn a i.download,
[news-article] .title-desc-btn a i.download,
[news-article] .basic-btn .none-btn i.download,
[news-article] .desc-btn .none-btn i.download,
[news-article] .title-basic-btn .none-btn i.download,
[news-article] .title-desc-btn .none-btn i.download {
  background-image: url('/img/common/btn-icon-download.png');
}
[news-article] .basic-btn .desc,
[news-article] .desc-btn .desc,
[news-article] .title-basic-btn .desc,
[news-article] .title-desc-btn .desc {
  font-size: 0.9375rem;
  line-height: 0.104375rem;
  letter-spacing: -0.015625rem;
  line-height: 1.67;
  color: #888;
  width: 85%;
  padding-bottom: 1.5rem;
}
[news-article] .basic-btn.desc-btn a,
[news-article] .desc-btn.desc-btn a,
[news-article] .title-basic-btn.desc-btn a,
[news-article] .title-desc-btn.desc-btn a,
[news-article] .basic-btn.title-desc-btn a,
[news-article] .desc-btn.title-desc-btn a,
[news-article] .title-basic-btn.title-desc-btn a,
[news-article] .title-desc-btn.title-desc-btn a,
[news-article] .basic-btn.desc-btn .none-btn,
[news-article] .desc-btn.desc-btn .none-btn,
[news-article] .title-basic-btn.desc-btn .none-btn,
[news-article] .title-desc-btn.desc-btn .none-btn,
[news-article] .basic-btn.title-desc-btn .none-btn,
[news-article] .desc-btn.title-desc-btn .none-btn,
[news-article] .title-basic-btn.title-desc-btn .none-btn,
[news-article] .title-desc-btn.title-desc-btn .none-btn {
  margin-bottom: 0.625rem;
}
[news-article] .basic-image,
[news-article] .basic-image-left,
[news-article] .basic-image-right,
[news-article] .full-image {
  padding-top: 3rem;
  padding-right: 0rem;
  padding-bottom: 3rem;
  padding-left: 0rem;
}
[news-article] .basic-image img,
[news-article] .basic-image-left img,
[news-article] .basic-image-right img,
[news-article] .full-image img {
  width: 100%;
  display: block;
}
[news-article] .basic-image .desc,
[news-article] .basic-image-left .desc,
[news-article] .basic-image-right .desc,
[news-article] .full-image .desc {
  font-size: 0.875rem;
  line-height: 0.09875rem;
  letter-spacing: -0.015625rem;
  line-height: 1.58;
  color: #888;
  padding-top: 1rem;
  text-align: center;
}
[news-article] .basic-image-left .desc {
  margin-left: 0 !important;
  text-align: left;
}
[news-article] .basic-image-right .desc {
  margin-right: 0 !important;
  text-align: right;
}
[news-article] .basic-image,
[news-article] .close-image,
[news-article] .zero-image,
[news-article] .basic-image-left,
[news-article] .close-image-left,
[news-article] .zero-image-left,
[news-article] .basic-image-right,
[news-article] .close-image-right,
[news-article] .zero-image-right {
  margin-top: 0rem;
  margin-right: 1.125rem;
  margin-bottom: 0rem;
  margin-left: 1.125rem;
}
[news-article] .close-image,
[news-article] .close-image-left,
[news-article] .close-image-right {
  padding-top: 0.5rem;
  padding-right: 0rem;
  padding-bottom: 0.5rem;
  padding-left: 0rem;
}
[news-article] .zero-image,
[news-article] .zero-image-left,
[news-article] .zero-image-right {
  padding-top: 0rem;
  padding-right: 0rem;
  padding-bottom: 0rem;
  padding-left: 0rem;
}
[news-article] .full-image .desc {
  margin-top: 0rem;
  margin-right: 1.125rem;
  margin-bottom: 0rem;
  margin-left: 1.125rem;
}
[news-article] .basic-image-left,
[news-article] .close-image-left,
[news-article] .zero-image-left {
  text-align: left;
}
[news-article] .basic-image-left img,
[news-article] .close-image-left img,
[news-article] .zero-image-left img {
  display: inline-block;
}
[news-article] .basic-image-right,
[news-article] .close-image-right,
[news-article] .zero-image-right {
  text-align: right;
}
[news-article] .basic-image-right img,
[news-article] .close-image-right img,
[news-article] .zero-image-right img {
  display: inline-block;
}
[news-article] .banner {
  margin-top: 0rem;
  margin-right: 1.125rem;
  margin-bottom: 0rem;
  margin-left: 1.125rem;
  padding-top: 0.75rem;
  padding-right: 0rem;
  padding-bottom: 0.75rem;
  padding-left: 0rem;
  display: block;
}
[news-article] .banner a {
  display: block;
}
[news-article] .banner a img {
  width: 100%;
}
[news-article] .double-image {
  padding-top: 3rem;
  padding-right: 0rem;
  padding-bottom: 3rem;
  padding-left: 0rem;
  margin-top: 0rem;
  margin-right: 1.125rem;
  margin-bottom: 0rem;
  margin-left: 1.125rem;
}
[news-article] .double-image .left {
  margin-bottom: 1.5rem;
}
[news-article] .double-image .left,
[news-article] .double-image .right {
  display: block;
}
[news-article] .double-image .left img,
[news-article] .double-image .right img {
  width: 100%;
  display: block;
}
[news-article] .double-image .desc {
  font-size: 0.75rem;
  line-height: 0.09875rem;
  letter-spacing: -0.015625rem;
  line-height: 1.58;
  color: #888;
  padding-top: 1rem;
  text-align: center;
}
[news-article] .carousel {
  overflow: hidden;
}
[news-article] .basic-carousel {
  width: 17.75rem;
  margin-top: 0rem;
  margin-right: 1.125rem;
  margin-bottom: 0rem;
  margin-left: 1.125rem;
  padding-top: 3rem;
  padding-right: 0rem;
  padding-bottom: 3rem;
  padding-left: 0rem;
}
[news-article] .basic-carousel .swiper-container {
  overflow: visible;
  width: 17.75rem;
}
[news-article] .basic-carousel .swiper-container .slide-item {
  display: block;
  margin-right: 1rem;
}
[news-article] .basic-carousel .swiper-container .slide-item .image-area {
  width: 100%;
  height: 11.1875rem;
  overflow: hidden;
  justify-content: center;
  align-content: center;
  display: flex;
}
[news-article] .basic-carousel .swiper-container .slide-item .image-area img {
  width: 100%;
  display: block;
  max-height: 100%;
  object-fit: cover;
}
[news-article] .basic-carousel .swiper-container .slide-item:last-of-type {
  margin: 0rem;
}
[news-article] .basic-carousel .swiper-container .slide-item .desc {
  font-size: 0.75rem;
  line-height: 0.09875rem;
  letter-spacing: -0.015625rem;
  line-height: 1.58;
  color: #888;
  padding-top: 1rem;
  text-align: center;
}
[news-article] .basic-carousel .swiper-navigation {
  display: none;
  z-index: 5;
}
[news-article] .basic-carousel .swiper-navigation .swiper-button-prev,
[news-article] .basic-carousel .swiper-navigation .swiper-button-next {
  color: transparent;
  width: 2.25rem;
  height: 2.25rem;
  background-size: contain;
  display: block;
}
[news-article] .basic-carousel .swiper-navigation .swiper-button-prev {
  left: -3.375rem;
  top: 50%;
  right: auto;
  bottom: auto;
  transform: translateY(-50%);
  background-image: url('/img/common/carousel-prev.png');
}
[news-article] .basic-carousel .swiper-navigation .swiper-button-next {
  right: -3.375rem;
  top: 50%;
  left: auto;
  bottom: auto;
  transform: translateY(-50%);
  background-image: url('/img/common/carousel-next.png');
}
[news-article] .basic-carousel .swiper-navigation .swiper-button-disabled {
  opacity: 0;
}
[news-article] .dot-carousel {
  width: 100%;
  padding-top: 3rem;
  padding-right: 0rem;
  padding-bottom: 3rem;
  padding-left: 0rem;
  position: relative;
}
[news-article] .dot-carousel .swiper-container .slide-item {
  display: block;
}
[news-article] .dot-carousel .swiper-container .slide-item .image-area {
  width: 100%;
  height: 15.5rem;
  overflow: hidden;
  justify-content: center;
  align-content: center;
  display: flex;
}
[news-article] .dot-carousel .swiper-container .slide-item .image-area img {
  width: 100%;
  display: block;
  max-height: 100%;
  object-fit: cover;
}
[news-article] .dot-carousel .swiper-container .slide-item .desc {
  font-size: 0.75rem;
  line-height: 0.09875rem;
  letter-spacing: -0.015625rem;
  line-height: 1.58;
  color: #888;
  padding-top: 1rem;
  text-align: center;
}
[news-article] .dot-carousel .swiper-navigation {
  display: none;
  z-index: 5;
}
[news-article] .dot-carousel .swiper-navigation .swiper-button-prev,
[news-article] .dot-carousel .swiper-navigation .swiper-button-next {
  color: transparent;
  width: 2.25rem;
  height: 2.25rem;
  background-size: contain;
}
[news-article] .dot-carousel .swiper-navigation .swiper-button-prev {
  left: 0rem;
  top: 50%;
  right: auto;
  bottom: auto;
  transform: translateY(-50%);
  background-image: url('/img/common/carousel-prev.png');
}
[news-article] .dot-carousel .swiper-navigation .swiper-button-next {
  right: 0rem;
  top: 50%;
  left: auto;
  bottom: auto;
  transform: translateY(-50%);
  background-image: url('/img/common/carousel-next.png');
}
[news-article] .dot-carousel .swiper-navigation .swiper-button-disabled {
  opacity: 0;
}
[news-article] .dot-carousel .swiper-pagination {
  left: 50%;
  transform: translateX(-50%);
  position: relative;
  display: block;
  margin-top: 1.25rem;
}
[news-article] .dot-carousel .swiper-pagination .swiper-pagination-bullet {
  margin-top: 0rem;
  margin-right: 0.375rem;
  margin-bottom: 0rem;
  margin-left: 0.375rem;
  width: 0.5rem;
  height: 0.5rem;
  background-color: #ddd;
  opacity: 1;
}
[news-article] .dot-carousel .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #888;
}
[news-article] .youtube {
  padding-top: 3rem;
  padding-right: 1rem;
  padding-bottom: 3rem;
  padding-left: 1rem;
}
[news-article] .youtube .video-wrap {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}
[news-article] .youtube .video-wrap .youtube-video {
  position: absolute;
  left: 0rem;
  top: 0rem;
  right: auto;
  bottom: auto;
  right: 0rem;
  bottom: 0rem;
  left: auto;
  top: auto;
  width: 100%;
  height: 100%;
}
[news-article] .youtube .desc {
  font-size: 0.75rem;
  line-height: 0.09875rem;
  letter-spacing: -0.015625rem;
  line-height: 1.58;
  color: #888;
  padding-top: 1rem;
  text-align: center;
}
[news-article] .single-video,
[news-article] .single-video-loop {
  margin-top: 0rem;
  margin-right: 1.125rem;
  margin-bottom: 0rem;
  margin-left: 1.125rem;
  padding-top: 3rem;
  padding-right: 0rem;
  padding-bottom: 3rem;
  padding-left: 0rem;
}
[news-article] .single-video video,
[news-article] .single-video-loop video {
  width: 100%;
}
[news-article] .single-video .desc,
[news-article] .single-video-loop .desc {
  font-size: 0.75rem;
  line-height: 0.09875rem;
  letter-spacing: -0.015625rem;
  line-height: 1.58;
  color: #888;
  padding-top: 1rem;
  text-align: center;
}
[news-article] .big-video,
[news-article] .big-video-loop {
  width: 100%;
  max-width: 120rem;
  margin-left: auto;
  margin-right: auto;
}
[news-article] .big-video video,
[news-article] .big-video-loop video {
  width: 100%;
}
[news-article] .big-video .desc,
[news-article] .big-video-loop .desc {
  font-size: 0.75rem;
  line-height: 0.09875rem;
  letter-spacing: -0.015625rem;
  line-height: 1.58;
  color: #888;
  padding-top: 1rem;
  text-align: center;
}
[news-article] .line {
  margin-top: 0rem;
  margin-right: 1.125rem;
  margin-bottom: 0rem;
  margin-left: 1.125rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-top: 1px solid #ddd;
}
[news-article] .blank.blank-1 {
  height: 1.5rem;
}
[news-article] .blank.blank-2 {
  height: 3rem;
}
[news-article] .blank.blank-25 {
  height: 3.75rem;
}
[news-article] .blank.blank-4 {
  height: 6rem;
}
[news-article] .blank.blank-5 {
  height: 7.5rem;
}
[news-article] .reference {
  margin-top: 0rem;
  margin-right: 1.125rem;
  margin-bottom: 0rem;
  margin-left: 1.125rem;
  padding-top: 3.75rem;
  padding-right: 0rem;
  padding-bottom: 3.75rem;
  padding-left: 0rem;
}
[news-article] .reference .title {
  padding-top: 1.5rem;
  padding-right: 0rem;
  padding-bottom: 1.5rem;
  padding-left: 0rem;
  font-size: 0.875rem;
  line-height: 0.106875rem;
  letter-spacing: -0.015625rem;
  line-height: 1.71;
  color: #555;
  font-weight: 500;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
[news-article] .reference .text {
  padding-top: 1rem;
}
[news-article] .reference .text p {
  font-size: 0.75rem;
  line-height: 0.114375rem;
  letter-spacing: -0.015625rem;
  line-height: 1.83;
  color: #555;
  padding-bottom: 1rem;
}
[news-article] .reference .text p a {
  color: #014890;
  text-decoration: none;
}
[news-article] .author,
[news-article] .author-title {
  margin-top: 0rem;
  margin-right: 1.125rem;
  margin-bottom: 0rem;
  margin-left: 1.125rem;
  padding-top: 1rem;
  padding-right: 0rem;
  padding-bottom: 1rem;
  padding-left: 0rem;
}
[news-article] .author .profile-img,
[news-article] .author-title .profile-img {
  width: 3.75rem;
  height: 3.75rem;
  display: inline-block;
  vertical-align: middle;
}
[news-article] .author .profile-img img,
[news-article] .author-title .profile-img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
[news-article] .author .text-area,
[news-article] .author-title .text-area {
  display: inline-block;
  width: calc(100% - 66px);
  box-sizing: border-box;
  padding-left: 1.5rem;
  vertical-align: middle;
}
[news-article] .author .text-area .name,
[news-article] .author-title .text-area .name {
  font-size: 1rem;
  line-height: 0.09375rem;
  letter-spacing: -0.015625rem;
  line-height: 1.5;
  font-weight: 500;
}
[news-article] .author .text,
[news-article] .author-title .text {
  font-size: 0.875rem;
  line-height: 0.098125rem;
  letter-spacing: -0.015625rem;
  line-height: 1.57;
  color: #666;
  padding-top: 1rem;
}
[news-article] .audio {
  margin-top: 0rem;
  margin-right: 1.125rem;
  margin-bottom: 0rem;
  margin-left: 1.125rem;
  padding-top: 1rem;
  padding-right: 0rem;
  padding-bottom: 1rem;
  padding-left: 0rem;
}
[news-article] .audio .desc {
  padding-top: 1rem;
  font-size: 0.75rem;
  line-height: 0.09875rem;
  letter-spacing: -0.015625rem;
  line-height: 1.58;
  color: #333;
  font-weight: 500;
}
[news-article] section.tags {
  padding-top: 3rem;
  padding-left: 1.125rem;
  padding-right: 1.125rem;
}
[news-article] section.tags ul li {
  display: inline-block;
  margin-right: 0.625rem;
  margin-bottom: 0.625rem;
}
[news-article] section.tags ul li a {
  display: block;
  font-size: 0.875rem;
  border-radius: 0.25rem;
  font-weight: 500;
  color: #666;
  border: 1px solid #e0e0e0;
  padding-top: 0.25rem;
  padding-right: 0.5rem;
  padding-bottom: 0.3125rem;
  padding-left: 0.5rem;
  text-decoration: none;
}
[news-article] section.related {
  padding-top: 8.4375rem;
  padding-left: 1.125rem;
  padding-right: 1.125rem;
  max-width: 167.5rem;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
}
[news-article] section.related .name {
  font-size: 1.5rem;
  font-weight: 700;
  padding-bottom: 1.5rem;
}
[news-article] section.related > ul {
  display: grid;
  grid-template-columns: 1fr;
}
[news-article] section.related > ul li {
  margin-bottom: 3rem;
}
[news-article] section.related > ul li a {
  text-decoration: none;
}
[news-article] section.related > ul li:last-of-type {
  margin-bottom: 0rem;
}
.no-touch [news-article] section.related > ul li:hover .img-wrap img {
  transform: scale(1.1);
}
[news-article] section.related > ul li .img-wrap {
  margin-bottom: 1.25rem;
  width: 100%;
  aspect-ratio: 1.88888889;
  overflow: hidden;
  position: relative;
}
[news-article] section.related > ul li .img-wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1);
  transition: transform 1s cubic-bezier(0, 0, 0.13, 1.04);
}
[news-article] section.related > ul li p {
  color: #333;
}
[news-article] section.related > ul li .tag {
  font-size: 0.75rem;
  padding-bottom: 0.625rem;
}
[news-article] section.related > ul li .tag span {
  margin-left: 0.75rem;
  display: inline-block;
  font-weight: 500;
}
[news-article] section.related > ul li .title {
  font-size: 1.125rem;
  line-height: 0.100625rem;
  letter-spacing: -0.015625rem;
  line-height: 1.61;
  font-weight: 700;
}
[news-article].play section.related > ul li {
  margin-bottom: 1.25rem;
  position: relative;
}
[news-article].play section.related > ul li .img-wrap {
  aspect-ratio: 0.6;
  margin-bottom: 0rem;
}
[news-article].play section.related > ul li p {
  color: #fff;
}
[news-article].play section.related > ul li .text {
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  left: 0rem;
  bottom: 0rem;
  right: auto;
  top: auto;
  padding-top: 2.25rem;
  padding-right: 1.5rem;
  padding-bottom: 2.25rem;
  padding-left: 1.5rem;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
}
[news-article].play section.related > ul li .sns-wrap {
  position: absolute;
  left: 0rem;
  top: 0rem;
  right: auto;
  bottom: auto;
  width: 100%;
  height: 4.375rem;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
}
[news-article].play section.related > ul li .sns-wrap [sns-share] {
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  margin-top: 0.75rem;
  margin-right: 0.875rem;
  margin-bottom: 0.75rem;
  margin-left: 0.875rem;
  position: absolute;
  right: 0rem;
  top: 0rem;
  left: auto;
  bottom: auto;
}
[news-article].play section.related > ul li .sns-wrap [sns-share] a.btn {
  background-image: url('/img/common/ic-share-more.png');
  background-repeat: no-repeat;
  background-size: contain;
}
[news-article].play section.related > ul li .sns-wrap [sns-share] .share-popup {
  right: 0rem;
  top: 1.5625rem;
  left: auto;
  bottom: auto;
  transform-origin: right top;
}
@media (min-width: 768px) {
  [news-article] {
    padding-bottom: 11.25rem;
  }
  [news-article] .hero {
    padding-top: 8.25rem;
    padding-right: 0rem;
    padding-bottom: 3rem;
    padding-left: 0rem;
  }
  [news-article] .hero h2 {
    font-size: 2.125rem;
    line-height: 0.09375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.5;
    width: 40.625rem;
    margin-left: auto;
    margin-right: auto;
  }
  [news-article] .hero .text-area {
    width: 30rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
  }
  [news-article].common .hero .desc {
    padding-bottom: 2.25rem;
  }
  [news-article].common .hero .share-btn-wrap [sns-share] {
    margin: 0rem;
  }
  [news-article].magazine .hero .kv-img {
    aspect-ratio: 1.77777778;
    height: auto;
  }
  [news-article].full .hero,
  [news-article].full-video .hero {
    padding: 0rem;
    width: 100%;
    height: auto;
    aspect-ratio: 1.77777778;
  }
  [news-article].full .hero .text-area,
  [news-article].full-video .hero .text-area {
    padding: 0rem;
    width: 30rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
  }
  [news-article].full .hero h2,
  [news-article].full-video .hero h2 {
    padding-bottom: 1.5rem;
  }
  [news-article].full .hero button,
  [news-article].full-video .hero button {
    margin-bottom: 2.25rem;
  }
  [news-article] .h0 h3 {
    font-size: 3rem;
    line-height: 0.0675rem;
    letter-spacing: -0.015625rem;
    line-height: 1.08;
    width: 30rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
    padding-top: 1.25rem;
    padding-right: 0rem;
    padding-bottom: 1.25rem;
    padding-left: 0rem;
  }
  [news-article] h3 {
    font-size: 1.625rem;
    line-height: 0.09375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.5;
    width: 30rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
    padding-top: 1.25rem;
    padding-right: 0rem;
    padding-bottom: 1.25rem;
    padding-left: 0rem;
  }
  [news-article] h4 {
    font-size: 1.25rem;
    line-height: 0.1rem;
    letter-spacing: -0.015625rem;
    line-height: 1.6;
    width: 30rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
    padding-top: 1.25rem;
    padding-right: 0rem;
    padding-bottom: 1.25rem;
    padding-left: 0rem;
  }
  [news-article] h5 {
    width: 30rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
  }
  [news-article] .prepend-desc {
    padding-top: 1.25rem;
  }
  [news-article] .prepend-desc p {
    width: 30rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
  }
  [news-article] .body-text {
    padding-top: 1.25rem;
    padding-right: 0rem;
    padding-bottom: 1.25rem;
    padding-left: 0rem;
    width: 30rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
  }
  [news-article] .subtitle-text {
    width: 30rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
  }
  [news-article] .list-text,
  [news-article] .list-text-1,
  [news-article] .list-text-2,
  [news-article] .list-text-3,
  [news-article] .list-text-4 {
    width: 30rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
  }
  [news-article] .list-text .title,
  [news-article] .list-text-1 .title,
  [news-article] .list-text-2 .title,
  [news-article] .list-text-3 .title,
  [news-article] .list-text-4 .title {
    margin-top: 1.25rem;
  }
  [news-article] .list-text .title h5,
  [news-article] .list-text-1 .title h5,
  [news-article] .list-text-2 .title h5,
  [news-article] .list-text-3 .title h5,
  [news-article] .list-text-4 .title h5 {
    padding-left: 1.5rem;
  }
  [news-article] .small-text {
    width: 30rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
    padding-top: 1.25rem;
    padding-right: 0rem;
    padding-bottom: 1.25rem;
    padding-left: 0rem;
  }
  [news-article] .short-quote,
  [news-article] .long-quote,
  [news-article] .en-quote {
    width: 30rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
  }
  [news-article] .short-quote p.main {
    font-size: 1.5rem;
    line-height: 0.09875rem;
    letter-spacing: -0.015625rem;
    line-height: 1.58;
  }
  [news-article] .short-quote .sub {
    font-size: 1rem;
    line-height: 0.101875rem;
    letter-spacing: -0.015625rem;
    line-height: 1.63;
  }
  [news-article] .long-quote p.main {
    font-size: 1.25rem;
    line-height: 0.10625rem;
    letter-spacing: -0.015625rem;
    line-height: 1.7;
  }
  [news-article] .en-quote .quote-comma:first-of-type {
    margin-bottom: 1.5rem;
  }
  [news-article] .en-quote .quote-comma:last-of-type {
    margin-top: 1.5rem;
  }
  [news-article] .profile {
    width: 30rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
  }
  [news-article] .profile .name {
    padding-top: 1.25rem;
    padding-right: 0rem;
    padding-bottom: 0.5rem;
    padding-left: 0rem;
  }
  [news-article] .profile .sum {
    font-size: 1rem;
    line-height: 0.109375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.75;
    padding-bottom: 1.25rem;
  }
  [news-article] .profile .body-text {
    margin: 0rem;
    padding-top: 0rem;
    padding-right: 0rem;
    padding-bottom: 2.25rem;
    padding-left: 0rem;
  }
  [news-article] .profile hr {
    display: block;
    border-top: 1px solid #ddd;
    margin-bottom: 1.25rem;
  }
  [news-article] .footnote {
    width: 30rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
  }
  [news-article] .dot-list {
    width: 30rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
    padding-top: 1.25rem;
    padding-right: 0rem;
    padding-bottom: 1.25rem;
    padding-left: 0rem;
  }
  [news-article] .indent-dot-list {
    width: 30rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
    padding-top: 0rem;
    padding-right: 0rem;
    padding-bottom: 1.25rem;
    padding-left: 1.1875rem;
  }
  [news-article] .number-list {
    width: 30rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
    padding-top: 1.25rem;
    padding-right: 0rem;
    padding-bottom: 1.25rem;
    padding-left: 0rem;
  }
  [news-article] .basic-btn,
  [news-article] .desc-btn,
  [news-article] .title-basic-btn,
  [news-article] .title-desc-btn {
    width: 24rem;
    margin-left: auto;
    margin-right: auto;
  }
  [news-article] .basic-btn .title,
  [news-article] .desc-btn .title,
  [news-article] .title-basic-btn .title,
  [news-article] .title-desc-btn .title {
    padding-top: 3rem;
    padding-right: 0rem;
    padding-bottom: 1.25rem;
    padding-left: 0rem;
  }
  [news-article] .basic-image,
  [news-article] .basic-image-left,
  [news-article] .basic-image-right,
  [news-article] .full-image {
    padding-top: 3.75rem;
    padding-right: 0rem;
    padding-bottom: 3.75rem;
    padding-left: 0rem;
  }
  [news-article] .basic-image .desc,
  [news-article] .basic-image-left .desc,
  [news-article] .basic-image-right .desc,
  [news-article] .full-image .desc {
    padding-top: 1rem;
    width: 30rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
  }
  [news-article] .close-image,
  [news-article] .close-image-left,
  [news-article] .close-image-right {
    padding-top: 0.75rem;
    padding-right: 0rem;
    padding-bottom: 0.75rem;
    padding-left: 0rem;
  }
  [news-article] .zero-image,
  [news-article] .zero-image-left,
  [news-article] .zero-image-right {
    padding-top: 0rem;
    padding-right: 0rem;
    padding-bottom: 0rem;
    padding-left: 0rem;
  }
  [news-article] .basic-image,
  [news-article] .close-image,
  [news-article] .zero-image,
  [news-article] .basic-image-left,
  [news-article] .close-image-left,
  [news-article] .zero-image-left,
  [news-article] .basic-image-right,
  [news-article] .close-image-right,
  [news-article] .zero-image-right {
    width: 36.75rem;
    margin-left: auto;
    margin-right: auto;
  }
  [news-article] .basic-image img,
  [news-article] .close-image img,
  [news-article] .zero-image img,
  [news-article] .basic-image-left img,
  [news-article] .close-image-left img,
  [news-article] .zero-image-left img,
  [news-article] .basic-image-right img,
  [news-article] .close-image-right img,
  [news-article] .zero-image-right img {
    width: auto;
    margin: 0 auto;
    max-width: 100%;
  }
  [news-article] .basic-image-left img,
  [news-article] .close-image-left img,
  [news-article] .zero-image-left img,
  [news-article] .basic-image-right img,
  [news-article] .close-image-right img,
  [news-article] .zero-image-right img {
    margin: 0;
  }
  [news-article] .banner {
    width: 30rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
  }
  [news-article] .double-image {
    padding-top: 3.75rem;
    padding-right: 0rem;
    padding-bottom: 3.75rem;
    padding-left: 0rem;
    width: 36.75rem;
    margin-left: auto;
    margin-right: auto;
  }
  [news-article] .double-image .left,
  [news-article] .double-image .right {
    width: 50%;
    display: inline-block;
    box-sizing: border-box;
  }
  [news-article] .double-image .left {
    margin: 0rem;
    padding-right: 0.625rem;
  }
  [news-article] .double-image .right {
    padding-left: 0.625rem;
  }
  [news-article] .double-image .desc {
    padding-top: 1.25rem;
  }
  [news-article] .basic-carousel {
    width: 30rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
    padding-top: 3.75rem;
    padding-right: 0rem;
    padding-bottom: 3.75rem;
    padding-left: 0rem;
    position: relative;
  }
  [news-article] .basic-carousel .swiper-container {
    width: 30rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
    width: 23.4375rem;
    margin: 0rem;
  }
  [news-article] .basic-carousel .swiper-container .slide-item {
    margin-right: 1.25rem;
  }
  [news-article] .basic-carousel .swiper-container .slide-item .image-area {
    height: 14.75rem;
    width: 100%;
  }
  [news-article] .basic-carousel .swiper-container .slide-item .desc {
    padding-top: 1.25rem;
  }
  [news-article] .basic-carousel .swiper-navigation {
    display: block;
  }
  [news-article] .dot-carousel {
    width: 36.75rem;
    margin-left: auto;
    margin-right: auto;
    padding-top: 3.75rem;
    padding-right: 0rem;
    padding-bottom: 3.75rem;
    padding-left: 0rem;
  }
  [news-article] .dot-carousel .swiper-container {
    width: 36.75rem;
    margin-left: auto;
    margin-right: auto;
  }
  [news-article] .dot-carousel .swiper-container .slide-item {
    width: 100%;
  }
  [news-article] .dot-carousel .swiper-container .slide-item .image-area {
    height: 24.3125rem;
    width: 100%;
  }
  [news-article] .dot-carousel .swiper-container .slide-item .desc {
    width: 30rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
    padding-top: 1.25rem;
  }
  [news-article] .dot-carousel .swiper-navigation {
    display: block;
  }
  [news-article] .dot-carousel .swiper-pagination {
    margin-top: 1.5rem;
  }
  [news-article] .youtube {
    padding-top: 3.75rem;
    padding-right: 0rem;
    padding-bottom: 3.75rem;
    padding-left: 0rem;
    width: 36.75rem;
    margin-left: auto;
    margin-right: auto;
  }
  [news-article] .youtube .desc {
    padding-top: 1.25rem;
  }
  [news-article] .single-video,
  [news-article] .single-video-loop {
    width: 36.75rem;
    margin-left: auto;
    margin-right: auto;
    padding-top: 3.75rem;
    padding-right: 0rem;
    padding-bottom: 3.75rem;
    padding-left: 0rem;
  }
  [news-article] .single-video .desc,
  [news-article] .single-video-loop .desc {
    padding-top: 1.25rem;
  }
  [news-article] .big-video .desc,
  [news-article] .big-video-loop .desc {
    padding-top: 1.25rem;
  }
  [news-article] .line {
    width: 30rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  [news-article] .reference {
    width: 30rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
  }
  [news-article] .reference .text {
    padding-top: 1.25rem;
  }
  [news-article] .reference .text p {
    font-size: 0.875rem;
    line-height: 0.098125rem;
    letter-spacing: -0.015625rem;
    line-height: 1.57;
    padding-bottom: 1.25rem;
  }
  [news-article] .author,
  [news-article] .author-title {
    width: 30rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
    padding-top: 1.25rem;
    padding-right: 0rem;
    padding-bottom: 1.25rem;
    padding-left: 0rem;
  }
  [news-article] .author .text,
  [news-article] .author-title .text {
    padding-top: 1.25rem;
  }
  [news-article] .audio {
    width: 30rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
    padding-top: 1.25rem;
    padding-right: 0rem;
    padding-bottom: 1.25rem;
    padding-left: 0rem;
  }
  [news-article] .audio .desc {
    padding-top: 1.25rem;
  }
  [news-article] section.tags {
    padding-top: 5.25rem;
    padding-right: 0rem;
    padding-bottom: 0rem;
    padding-left: 0rem;
    width: 30rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
  }
  [news-article] section.related {
    padding-top: 8.75rem;
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
  [news-article] section.related > ul {
    grid-template-columns: 1fr 1fr;
    column-gap: 18px;
  }
  [news-article] section.related > ul li {
    margin-bottom: 3.5rem;
  }
  [news-article].play section.related > ul li {
    margin-bottom: 1.125rem;
  }
  [news-article].play section.related > ul li .sns-wrap [sns-share] {
    width: 1.25rem;
    height: 1.25rem;
    margin: 0.75rem;
  }
}
@media (min-width: 1024px) {
  [news-article] {
    padding-bottom: 12.5rem;
  }
  [news-article] .hero {
    padding-top: 10.25rem;
    padding-right: 0rem;
    padding-bottom: 4.5rem;
    padding-left: 0rem;
  }
  [news-article] .hero h2 {
    font-size: 2.25rem;
    line-height: 0.09375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.5;
    width: 48.875rem;
    padding-bottom: 2.375rem;
  }
  [news-article] .hero .text-area {
    width: 40.625rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
  }
  [news-article] .hero .tag {
    padding-bottom: 1.375rem;
  }
  [news-article] .hero .desc p {
    font-size: 1.125rem;
    line-height: 0.118125rem;
    letter-spacing: -0.015625rem;
    line-height: 1.89;
  }
  [news-article].common .hero .tag {
    padding-bottom: 1.5rem;
  }
  [news-article].full .hero,
  [news-article].full-video .hero {
    margin-bottom: 4.5rem;
  }
  [news-article].full .hero .text-area,
  [news-article].full-video .hero .text-area {
    width: 40.625rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
  }
  [news-article].full .hero button,
  [news-article].full-video .hero button {
    margin-bottom: 3rem;
  }
  [news-article] .h0 h3 {
    font-size: 4rem;
    line-height: 0.06875rem;
    letter-spacing: -0.015625rem;
    line-height: 1.1;
    padding-top: 1.5rem;
    padding-right: 0rem;
    padding-bottom: 1.5rem;
    padding-left: 0rem;
    width: 40.625rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
  }
  [news-article] h3 {
    font-size: 2.125rem;
    line-height: 0.09375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.5;
    padding-top: 1.5rem;
    padding-right: 0rem;
    padding-bottom: 1.5rem;
    padding-left: 0rem;
    width: 40.625rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
  }
  [news-article] h4 {
    font-size: 1.625rem;
    line-height: 0.09375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.5;
    padding-top: 1.5rem;
    padding-right: 0rem;
    padding-bottom: 1.5rem;
    padding-left: 0rem;
    width: 40.625rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
  }
  [news-article] h5 {
    font-size: 1.25rem;
    line-height: 0.1rem;
    letter-spacing: -0.015625rem;
    line-height: 1.6;
    padding-top: 1.5rem;
    padding-right: 0rem;
    padding-bottom: 0.75rem;
    padding-left: 0rem;
    width: 40.625rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
  }
  [news-article] .prepend-desc {
    padding-top: 1.5rem;
  }
  [news-article] .prepend-desc p {
    padding-bottom: 0.625rem;
    width: 40.625rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
  }
  [news-article] .prepend-desc h3 {
    padding-top: 0rem;
  }
  [news-article] .body-text {
    padding-top: 1.5rem;
    padding-right: 0rem;
    padding-bottom: 1.5rem;
    padding-left: 0rem;
    width: 40.625rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
  }
  [news-article] .body-text p {
    font-size: 1.125rem;
    line-height: 0.118125rem;
    letter-spacing: -0.015625rem;
    line-height: 1.89;
    min-height: 1.125rem;
  }
  [news-article] .subtitle-text {
    width: 40.625rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
  }
  [news-article] .list-text,
  [news-article] .list-text-1,
  [news-article] .list-text-2,
  [news-article] .list-text-3,
  [news-article] .list-text-4 {
    width: 40.625rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
  }
  [news-article] .list-text .title,
  [news-article] .list-text-1 .title,
  [news-article] .list-text-2 .title,
  [news-article] .list-text-3 .title,
  [news-article] .list-text-4 .title {
    margin-top: 1.5rem;
  }
  [news-article] .list-text .title i,
  [news-article] .list-text-1 .title i,
  [news-article] .list-text-2 .title i,
  [news-article] .list-text-3 .title i,
  [news-article] .list-text-4 .title i {
    width: 1.5rem;
    height: 1.5rem;
    left: 0rem;
    top: 1rem;
    right: auto;
    bottom: auto;
  }
  [news-article] .list-text .title h5,
  [news-article] .list-text-1 .title h5,
  [news-article] .list-text-2 .title h5,
  [news-article] .list-text-3 .title h5,
  [news-article] .list-text-4 .title h5 {
    padding-left: 1.5rem;
    font-size: 1.125rem;
    line-height: 0.118125rem;
    letter-spacing: -0.015625rem;
    line-height: 1.89;
  }
  [news-article] .list-text .body-text,
  [news-article] .list-text-1 .body-text,
  [news-article] .list-text-2 .body-text,
  [news-article] .list-text-3 .body-text,
  [news-article] .list-text-4 .body-text {
    margin: 0rem;
  }
  [news-article] .small-text {
    width: 40.625rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
    padding-top: 1.5rem;
    padding-right: 0rem;
    padding-bottom: 0.75rem;
    padding-left: 0rem;
  }
  [news-article] .short-quote,
  [news-article] .long-quote,
  [news-article] .en-quote {
    padding-top: 5.25rem;
    padding-right: 0rem;
    padding-bottom: 5.25rem;
    padding-left: 0rem;
    width: 40.625rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
  }
  [news-article] .quote-comma {
    width: 1.875rem;
    height: 1.875rem;
  }
  [news-article] .quote-comma:first-of-type {
    margin-bottom: 3.75rem;
  }
  [news-article] .quote-comma:last-of-type {
    margin-top: 3.75rem;
  }
  [news-article] .short-quote p.main {
    font-size: 1.75rem;
    line-height: 0.1025rem;
    letter-spacing: -0.015625rem;
    line-height: 1.64;
  }
  [news-article] .short-quote .sub {
    font-size: 1rem;
    line-height: 0.101875rem;
    letter-spacing: -0.015625rem;
    line-height: 1.63;
    padding-top: 2.25rem;
  }
  [news-article] .long-quote p.main {
    font-size: 1.5rem;
    line-height: 0.104375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.67;
  }
  [news-article] .long-quote .sub {
    font-size: 1rem;
    line-height: 0.101875rem;
    letter-spacing: -0.015625rem;
    line-height: 1.63;
    padding-top: 2.25rem;
  }
  [news-article] .long-quote .quote-comma:first-of-type {
    margin-bottom: 3rem;
  }
  [news-article] .long-quote .quote-comma:last-of-type {
    margin-top: 3rem;
  }
  [news-article] .en-quote p.main {
    font-size: 1.625rem;
    line-height: 0.09625rem;
    letter-spacing: -0.015625rem;
    line-height: 1.54;
  }
  [news-article] .en-quote .sub {
    padding-top: 1rem;
  }
  [news-article] .en-quote .quote-comma:first-of-type {
    margin-bottom: 2.25rem;
  }
  [news-article] .en-quote .quote-comma:last-of-type {
    margin-top: 2.25rem;
  }
  [news-article] .profile {
    width: 40.625rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
  }
  [news-article] .profile .name {
    font-size: 1.375rem;
    line-height: 0.090625rem;
    letter-spacing: -0.015625rem;
    line-height: 1.45;
    padding-top: 1.5rem;
    padding-right: 0rem;
    padding-bottom: 0.5rem;
    padding-left: 0rem;
  }
  [news-article] .profile .sum {
    font-size: 1.125rem;
    line-height: 0.118125rem;
    letter-spacing: -0.015625rem;
    line-height: 1.89;
    padding-bottom: 1.5rem;
  }
  [news-article] .profile .body-text {
    padding-top: 0rem;
    padding-right: 0rem;
    padding-bottom: 3rem;
    padding-left: 0rem;
  }
  [news-article] .profile hr {
    margin-bottom: 1.5rem;
  }
  [news-article] .footnote {
    width: 40.625rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
  }
  [news-article] .footnote li {
    font-size: 0.875rem;
    line-height: 0.098125rem;
    letter-spacing: -0.015625rem;
    line-height: 1.57;
  }
  [news-article] .dot-list {
    width: 40.625rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
    padding-top: 1.5rem;
    padding-right: 0rem;
    padding-bottom: 1.5rem;
    padding-left: 0rem;
  }
  [news-article] .dot-list p {
    font-size: 1.125rem;
    line-height: 0.118125rem;
    letter-spacing: -0.015625rem;
    line-height: 1.89;
  }
  [news-article] .indent-dot-list {
    width: 40.625rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
    padding-bottom: 1.5rem;
  }
  [news-article] .indent-dot-list p {
    font-size: 1.125rem;
    line-height: 0.118125rem;
    letter-spacing: -0.015625rem;
    line-height: 1.89;
  }
  [news-article] .number-list {
    width: 40.625rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
    padding-top: 1.5rem;
    padding-right: 0rem;
    padding-bottom: 1.5rem;
    padding-left: 0rem;
  }
  [news-article] .number-list p {
    font-size: 1.125rem;
    line-height: 0.118125rem;
    letter-spacing: -0.015625rem;
    line-height: 1.89;
  }
  [news-article] .basic-btn,
  [news-article] .desc-btn,
  [news-article] .title-basic-btn,
  [news-article] .title-desc-btn {
    width: 35rem;
  }
  [news-article] .basic-btn .title,
  [news-article] .desc-btn .title,
  [news-article] .title-basic-btn .title,
  [news-article] .title-desc-btn .title {
    font-size: 1.25rem;
    line-height: 0.1rem;
    letter-spacing: -0.015625rem;
    line-height: 1.6;
    padding-top: 3.75rem;
    padding-right: 0rem;
    padding-bottom: 1.5rem;
    padding-left: 0rem;
  }
  [news-article] .basic-btn a,
  [news-article] .desc-btn a,
  [news-article] .title-basic-btn a,
  [news-article] .title-desc-btn a,
  [news-article] .basic-btn .none-btn,
  [news-article] .desc-btn .none-btn,
  [news-article] .title-basic-btn .none-btn,
  [news-article] .title-desc-btn .none-btn {
    font-size: 1rem;
    line-height: 0.09375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.5;
    margin-top: 2.5rem;
    margin-right: 0rem;
    margin-bottom: 2.5rem;
    margin-left: 0rem;
    padding-right: 5rem;
  }
  [news-article] .basic-btn .desc,
  [news-article] .desc-btn .desc,
  [news-article] .title-basic-btn .desc,
  [news-article] .title-desc-btn .desc {
    padding-bottom: 2.5rem;
  }
  [news-article] .basic-btn.desc-btn a,
  [news-article] .desc-btn.desc-btn a,
  [news-article] .title-basic-btn.desc-btn a,
  [news-article] .title-desc-btn.desc-btn a,
  [news-article] .basic-btn.title-desc-btn a,
  [news-article] .desc-btn.title-desc-btn a,
  [news-article] .title-basic-btn.title-desc-btn a,
  [news-article] .title-desc-btn.title-desc-btn a,
  [news-article] .basic-btn.desc-btn .none-btn,
  [news-article] .desc-btn.desc-btn .none-btn,
  [news-article] .title-basic-btn.desc-btn .none-btn,
  [news-article] .title-desc-btn.desc-btn .none-btn,
  [news-article] .basic-btn.title-desc-btn .none-btn,
  [news-article] .desc-btn.title-desc-btn .none-btn,
  [news-article] .title-basic-btn.title-desc-btn .none-btn,
  [news-article] .title-desc-btn.title-desc-btn .none-btn {
    margin-bottom: 0.75rem;
  }
  [news-article] .basic-image,
  [news-article] .basic-image-left,
  [news-article] .basic-image-right,
  [news-article] .full-image {
    padding-top: 4.5rem;
    padding-right: 0rem;
    padding-bottom: 4.5rem;
    padding-left: 0rem;
  }
  [news-article] .basic-image .desc,
  [news-article] .basic-image-left .desc,
  [news-article] .basic-image-right .desc,
  [news-article] .full-image .desc {
    width: 40.625rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
  }
  [news-article] .basic-image,
  [news-article] .close-image,
  [news-article] .zero-image,
  [news-article] .basic-image-left,
  [news-article] .close-image-left,
  [news-article] .zero-image-left,
  [news-article] .basic-image-right,
  [news-article] .close-image-right,
  [news-article] .zero-image-right {
    width: 48.875rem;
  }
  [news-article] .banner {
    width: 40.625rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
  }
  [news-article] .double-image {
    padding-top: 4.5rem;
    padding-right: 0rem;
    padding-bottom: 4.5rem;
    padding-left: 0rem;
    width: 48.875rem;
  }
  [news-article] .basic-carousel {
    width: 40.625rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
    padding-top: 4.5rem;
    padding-right: 0rem;
    padding-bottom: 4.5rem;
    padding-left: 0rem;
  }
  [news-article] .basic-carousel .swiper-container {
    width: 27.5625rem;
  }
  [news-article] .basic-carousel .swiper-container .slide-item {
    margin-right: 1.5rem;
  }
  [news-article] .basic-carousel .swiper-container .slide-item .image-area {
    height: 17.375rem;
    width: 100%;
  }
  [news-article] .basic-carousel .swiper-container .slide-item .desc {
    padding-top: 1.5rem;
  }
  [news-article] .basic-carousel .swiper-navigation .swiper-button-prev {
    left: -4.375rem;
    top: 50%;
    right: auto;
    bottom: auto;
  }
  [news-article] .basic-carousel .swiper-navigation .swiper-button-next {
    right: -4.375rem;
    top: 50%;
    left: auto;
    bottom: auto;
  }
  [news-article] .dot-carousel {
    width: 48.875rem;
    padding-top: 4.5rem;
    padding-right: 0rem;
    padding-bottom: 4.5rem;
    padding-left: 0rem;
  }
  [news-article] .dot-carousel .swiper-container {
    width: 48.875rem;
  }
  [news-article] .dot-carousel .swiper-container .slide-item .image-area {
    height: 32.4375rem;
  }
  [news-article] .dot-carousel .swiper-container .slide-item .desc {
    width: 40.625rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
    padding-top: 1.5rem;
  }
  [news-article] .dot-carousel .swiper-navigation {
    display: block;
  }
  [news-article] .dot-carousel .swiper-pagination {
    margin-top: 1.5rem;
  }
  [news-article] .youtube {
    padding-top: 4.5rem;
    padding-right: 0rem;
    padding-bottom: 4.5rem;
    padding-left: 0rem;
    width: 48.875rem;
  }
  [news-article] .youtube .desc {
    padding-top: 1.5rem;
  }
  [news-article] .single-video,
  [news-article] .single-video-loop {
    width: 48.875rem;
    padding-top: 4.5rem;
    padding-right: 0rem;
    padding-bottom: 4.5rem;
    padding-left: 0rem;
  }
  [news-article] .single-video .desc,
  [news-article] .single-video-loop .desc {
    padding-top: 1.5rem;
  }
  [news-article] .big-video .desc,
  [news-article] .big-video-loop .desc {
    padding-top: 1.5rem;
  }
  [news-article] .line {
    width: 40.625rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  [news-article] .reference {
    width: 40.625rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
    padding-top: 5.25rem;
    padding-right: 0rem;
    padding-bottom: 5.25rem;
    padding-left: 0rem;
  }
  [news-article] .reference .title {
    font-size: 1rem;
    line-height: 0.101875rem;
    letter-spacing: -0.015625rem;
    line-height: 1.63;
  }
  [news-article] .reference .text {
    padding-top: 1.5rem;
  }
  [news-article] .reference .text p {
    padding-bottom: 1.5rem;
  }
  [news-article] .author,
  [news-article] .author-title {
    width: 40.625rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
    padding-top: 1.5rem;
    padding-right: 0rem;
    padding-bottom: 1.5rem;
    padding-left: 0rem;
  }
  [news-article] .author .text-area .name,
  [news-article] .author-title .text-area .name {
    font-size: 1rem;
    line-height: 0.09375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.5;
    font-weight: 500;
  }
  [news-article] .author .text-area .text,
  [news-article] .author-title .text-area .text {
    padding-top: 0.5rem;
  }
  [news-article] .author .profile-img {
    vertical-align: top;
  }
  [news-article] .audio {
    width: 40.625rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
    padding-top: 1.5rem;
    padding-right: 0rem;
    padding-bottom: 1.5rem;
    padding-left: 0rem;
  }
  [news-article] .audio .desc {
    padding-top: 1.5rem;
  }
  [news-article] section.tags {
    padding-top: 6rem;
    width: 40.625rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
  }
  [news-article] section.related {
    padding-top: 10.3125rem;
  }
  [news-article] section.related > ul {
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 14px;
  }
  [news-article] section.related > ul li {
    margin-bottom: 0rem;
  }
  [news-article].play section.related > ul li {
    margin-bottom: 0rem;
  }
  [news-article].play section.related > ul li .text {
    padding-top: 1.75rem;
    padding-right: 1.375rem;
    padding-bottom: 1.75rem;
    padding-left: 1.375rem;
  }
  [news-article].play section.related > ul li .sns-wrap [sns-share] {
    margin-top: 0.75rem;
    margin-right: 0.875rem;
    margin-bottom: 0.75rem;
    margin-left: 0.875rem;
  }
}
@media (min-width: 1440px) {
  [news-article] {
    padding-bottom: 17.5rem;
  }
  [news-article] .hero {
    padding-top: 10rem;
    padding-right: 0rem;
    padding-bottom: 4.5rem;
    padding-left: 0rem;
  }
  [news-article] .hero h2 {
    font-size: 3rem;
    line-height: 0.09375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.5;
    padding-bottom: 2.25rem;
  }
  [news-article] .hero .tag {
    padding-bottom: 1.5rem;
  }
  [news-article] .hero .desc p {
    font-size: 1.125rem;
    line-height: 0.118125rem;
    letter-spacing: -0.015625rem;
    line-height: 1.89;
  }
  [news-article].magazine .hero .text-area,
  [news-article].webtoon .hero .text-area {
    width: 68.75rem;
  }
  [news-article].full .hero h2,
  [news-article].full-video .hero h2 {
    padding-bottom: 2.25rem;
  }
  [news-article].full .hero .text-area,
  [news-article].full-video .hero .text-area {
    width: 68.75rem;
  }
  [news-article] .h0 h3 {
    font-size: 5rem;
    line-height: 0.06875rem;
    letter-spacing: -0.015625rem;
    line-height: 1.1;
  }
  [news-article] h3 {
    font-size: 2.5rem;
    line-height: 0.09375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.5;
  }
  [news-article] h4 {
    font-size: 1.75rem;
    line-height: 0.098125rem;
    letter-spacing: -0.015625rem;
    line-height: 1.57;
  }
  [news-article] .prepend-desc p {
    padding-bottom: 0.375rem;
  }
  [news-article] .basic-image,
  [news-article] .close-image,
  [news-article] .zero-image,
  [news-article] .basic-image-left,
  [news-article] .close-image-left,
  [news-article] .zero-image-left,
  [news-article] .basic-image-right,
  [news-article] .close-image-right,
  [news-article] .zero-image-right {
    width: 68.75rem;
  }
  [news-article] .double-image {
    width: 68.75rem;
  }
  [news-article] .short-quote p.main {
    font-size: 2rem;
    line-height: 0.101875rem;
    letter-spacing: -0.015625rem;
    line-height: 1.63;
  }
  [news-article] .long-quote p.main {
    font-size: 1.625rem;
    line-height: 0.103125rem;
    letter-spacing: -0.015625rem;
    line-height: 1.65;
  }
  [news-article] .basic-carousel .swiper-container {
    width: 33.625rem;
  }
  [news-article] .basic-carousel .swiper-container .slide-item .image-area {
    height: 21.25rem;
  }
  [news-article] .dot-carousel {
    width: 68.75rem;
  }
  [news-article] .dot-carousel .swiper-container {
    width: 68.75rem;
  }
  [news-article] .dot-carousel .swiper-container .slide-item .image-area {
    height: 45.625rem;
  }
  [news-article] .youtube {
    width: 68.75rem;
  }
  [news-article] .single-video,
  [news-article] .single-video-loop {
    width: 68.75rem;
  }
  [news-article] section.tags {
    padding-top: 7.5rem;
    width: 40.625rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    box-sizing: border-box;
  }
  [news-article] section.related {
    padding-top: 11.25rem;
    padding-left: 3rem;
    padding-right: 3rem;
  }
  [news-article] section.related .name {
    font-size: 1.625rem;
    padding-bottom: 2rem;
  }
  [news-article] section.related > ul {
    column-gap: 24px;
  }
  [news-article].play section.related > ul li {
    margin-bottom: 0rem;
  }
  [news-article].play section.related > ul li .text {
    padding-top: 2.25rem;
    padding-right: 1.875rem;
    padding-bottom: 2.25rem;
    padding-left: 1.875rem;
  }
  [news-article].play section.related > ul li .sns-wrap {
    height: 6.875rem;
  }
  [news-article].play section.related > ul li .sns-wrap [sns-share] {
    width: 1.5rem;
    height: 1.5rem;
    margin: 1rem;
  }
}
@media (min-width: 1600px) {
  [news-article].common .hero {
    padding-bottom: 3rem;
  }
  [news-article] section.related {
    padding-top: 13.75rem;
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }
  [news-article] section.related .name {
    font-size: 2.25rem;
    padding-bottom: 3rem;
  }
  [news-article] section.related > ul {
    column-gap: 33px;
  }
  [news-article] section.related > ul li {
    margin-bottom: 5.25rem;
  }
  [news-article] section.related > ul li .img-wrap {
    margin-bottom: 1.625rem;
  }
  [news-article] section.related > ul li .tag {
    font-size: 1rem;
    padding-bottom: 0.8125rem;
  }
  [news-article] section.related > ul li .tag span {
    margin-left: 1rem;
  }
  [news-article] section.related > ul li .title {
    font-size: 1.5rem;
    line-height: 0.09875rem;
    letter-spacing: -0.015625rem;
    line-height: 1.58;
  }
  [news-article].play section.related > ul li .text {
    padding-top: 3rem;
    padding-right: 2.5rem;
    padding-bottom: 3rem;
    padding-left: 2.5rem;
  }
  [news-article].play section.related > ul li .sns-wrap [sns-share] {
    width: 2rem;
    height: 2rem;
    margin-top: 1.1875rem;
    margin-right: 1.3125rem;
    margin-bottom: 1.1875rem;
    margin-left: 1.3125rem;
  }
}
