.t {
  transform: none;
}
[audio-player] {
  z-index: 99;
  position: relative;
}
[audio-player] .player {
  background-color: #000;
  padding-top: 0.3125rem;
  padding-right: 1.5rem;
  padding-bottom: 0.3125rem;
  padding-left: 1.5rem;
  border-radius: 0.25rem;
  font-size: 0rem;
  line-height: 0rem;
  line-height: 0;
}
[audio-player] .player .play-btn {
  opacity: 1;
  width: 1.25rem;
  height: 1.25rem;
  background-color: transparent;
  margin-right: 0rem;
  display: inline-block;
  background-image: url('/img/common/ic-white-play.png');
  background-size: contain;
  vertical-align: middle;
}
[audio-player] .player .sec {
  display: inline-block;
  width: 6.125rem;
  box-sizing: border-box;
  color: #fff;
  vertical-align: middle;
  margin: 0rem;
  padding-top: 0rem;
  padding-right: 1.125rem;
  padding-bottom: 0rem;
  padding-left: 0.75rem;
  font-size: 0.625rem;
  line-height: 0.1125rem;
  letter-spacing: -0.015625rem;
  line-height: 1.8;
  font-weight: 700;
}
[audio-player] .player .progress-bar {
  position: relative;
  width: calc(100% - 180px);
  display: inline-block;
  vertical-align: middle;
  padding-top: 0.625rem;
  padding-right: 0rem;
  padding-bottom: 0.625rem;
  padding-left: 0rem;
  background-color: transparent;
  cursor: pointer;
}
[audio-player] .player .progress-bar .bar-bgc {
  height: 0.25rem;
  width: 100%;
  pointer-events: none;
  background-color: #717171;
}
[audio-player] .player .progress-bar .bar-bgc span {
  height: 100%;
  width: 50%;
  display: block;
  background-color: #fff;
}
[audio-player] .player .volume {
  display: inline-block;
  margin-left: 1.5rem;
  vertical-align: middle;
}
[audio-player] .player .volume .volume-btn {
  padding: 0.0625rem;
  background-color: transparent;
  display: inline-block;
}
[audio-player] .player .volume .volume-btn span {
  width: 0.125rem;
  background-color: #717171;
  display: block;
}
[audio-player] .player .volume .volume-btn:nth-of-type(1) span {
  height: 0.1875rem;
}
[audio-player] .player .volume .volume-btn:nth-of-type(2) span {
  height: 0.3125rem;
}
[audio-player] .player .volume .volume-btn:nth-of-type(3) span {
  height: 0.4375rem;
}
[audio-player] .player .volume .volume-btn:nth-of-type(4) span {
  height: 0.5625rem;
}
[audio-player] .player .volume .volume-btn:nth-of-type(5) span {
  height: 0.6875rem;
}
[audio-player] .player .volume.volume-0 .volume-btn:nth-of-type(1) span {
  background-color: #fff;
}
[audio-player] .player .volume.volume-1 .volume-btn:nth-of-type(1) span,
[audio-player] .player .volume.volume-1 .volume-btn:nth-of-type(2) span {
  background-color: #fff;
}
[audio-player] .player .volume.volume-2 .volume-btn:nth-of-type(1) span,
[audio-player] .player .volume.volume-2 .volume-btn:nth-of-type(2) span,
[audio-player] .player .volume.volume-2 .volume-btn:nth-of-type(3) span {
  background-color: #fff;
}
[audio-player] .player .volume.volume-3 .volume-btn:nth-of-type(1) span,
[audio-player] .player .volume.volume-3 .volume-btn:nth-of-type(2) span,
[audio-player] .player .volume.volume-3 .volume-btn:nth-of-type(3) span,
[audio-player] .player .volume.volume-3 .volume-btn:nth-of-type(4) span {
  background-color: #fff;
}
[audio-player] .player .volume.volume-4 .volume-btn span {
  background-color: #fff;
}
[audio-player] .player.active .play-btn {
  background-image: url('/img/common/ic-white-pause.png');
}
[audio-player] .player.active .progress-bar span {
  transition: width 0.3s linear;
}
@media (min-width: 768px) {
  [audio-player] .player .volume {
    display: inline-block;
    margin-left: 1.5rem;
    vertical-align: middle;
  }
  [audio-player] .player .volume .volume-btn {
    padding: 0.0625rem;
    background-color: transparent;
  }
  [audio-player] .player .volume .volume-btn span {
    width: 0.1875rem;
    background-color: #717171;
    display: block;
  }
  [audio-player] .player .volume .volume-btn:nth-of-type(1) span {
    height: 0.25rem;
  }
  [audio-player] .player .volume .volume-btn:nth-of-type(2) span {
    height: 0.375rem;
  }
  [audio-player] .player .volume .volume-btn:nth-of-type(3) span {
    height: 0.5rem;
  }
  [audio-player] .player .volume .volume-btn:nth-of-type(4) span {
    height: 0.625rem;
  }
  [audio-player] .player .volume .volume-btn:nth-of-type(5) span {
    height: 0.75rem;
  }
}
